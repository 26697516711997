.project-card-view {
    background: linear-gradient(145deg, #2a0845 0%, #6441A5 100%);
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(100, 65, 165, 0.2);
    transition: all 0.5s ease;
    height: 100%;
    overflow: hidden;
}

.project-card-view:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(100, 65, 165, 0.3);
}

.project-card-view .card-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.project-card-view .card-text {
    font-family: 'Roboto', sans-serif;
}

.tech-badge {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-flex;
    align-items: center;
    font-size: 0.9em;
}

.btn-primary {
    background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
    border: none !important;
    border-radius: 25px;
    padding: 10px 20px;
    font-weight: bold;
    transition: all 0.3s ease;
}

.btn-primary:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(142, 45, 226, 0.4);
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}
